/*
 * Unpublished work. Copyright 2025 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React from 'react';
import { siemensAnalyticsEvents, siemensAnalyticsService } from 'services/SiemensAnalyticsService';
import { useAppViewPort } from 'webComponent/hooks';

import { PqLogoIcon } from 'assets';
import { ClearButton } from 'components/molecules/ClearButton';
import { DrawerContext, DrawerIds } from 'components/molecules/Drawer';
import { SearchInput } from 'components/molecules/SearchInput';
import { displayedVersion } from 'const';

const testIds = {
    main: 'main-page',
    thirdPartySoftwareInfo: 'main-page-third-party-software-info',
} as const;

export { testIds as MainPageTestIds };

const partQuestUrl = 'https://partquest.com/'; // TODO: [ts] Replace with region specific PartQuest Portal URL

const DEBUG = false;

export const MainPage = ({ children }: React.PropsWithChildren) => {
    const { appWidth, appHeight, currentScreenBreakpoint } = useAppViewPort();
    const { openDrawer } = React.useContext(DrawerContext);

    React.useEffect(() => {
        DEBUG && console.debug('MainPage:', { appWidth, appHeight, currentScreenBreakpoint });
    }, [appWidth, appHeight, currentScreenBreakpoint]);

    function handleRedirectToPartQuestPortal() {
        siemensAnalyticsService.logEvent(siemensAnalyticsEvents.createRedirectEvent());
        window.open(partQuestUrl, '_blank', 'noreferrer');
    }

    const showThirdPartySoftwareInfoHandler = () => {
        openDrawer(DrawerIds.ThirdPartyInfoDetails);
    };

    return (
        <main
            data-testid={testIds.main}
            className={`relative flex h-[${appHeight}px] min-w-[160px] flex-col bg-background-inverted px-1`}
        >
            <div className='flex items-center py-3'>
                <button
                    type='button'
                    className='pl-2 pr-1.5'
                    onClick={handleRedirectToPartQuestPortal}
                >
                    <PqLogoIcon />
                </button>
                <SearchInput className='flex-grow' />
                <ClearButton className='pl-1.5 pr-1' />
            </div>
            <div className='custom-scrollbar pl-2 pr-1'>{children}</div>
            <div className='fixed bottom-2 right-2 text-notation font-semibold text-text-hint_text_empty_screen'>
                <span
                    className='cursor-pointer'
                    data-testid={testIds.thirdPartySoftwareInfo}
                    onClick={showThirdPartySoftwareInfoHandler}
                >
                    Third Party Software Info
                </span>
                <span> | ver.{displayedVersion}</span>
            </div>
        </main>
    );
};
